import { createTheme } from '@mui/material/styles';

import NeoSansRegular from './fonts/subset-NeoSansW01.woff2';
import NeoSansMedium from './fonts/subset-NeoSansW01-Medium.woff2';
import NeoSansLight from './fonts/subset-NeoSansW01-Light.woff2';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00abe8',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          color: #4b4b4c;
          font-family: Roboto, Arial, Sans-Serif;
        }
        h1, h2, h3, h4, h5, h6 {
          font-family: neo_sans, Roboto, Arial, Sans-Serif;
        }
        @font-face {
          font-family: 'neo_sans';
          font-display: swap;
          font-style: normal;
          font-weight: normal;
          src: local('Droid Sans'), url(${NeoSansRegular}) format('woff2');
        }
        @font-face {
          font-family: 'neo_sans';
          font-display: swap;
          font-style: normal;
          font-weight: bold;
          src: local('Droid Sans'), url(${NeoSansMedium}) format('woff2');
        }
        @font-face {
          font-family: 'neo_sans';
          font-display: swap;
          font-style: normal;
          font-weight: 300;
          src: local('Droid Sans'), url(${NeoSansLight}) format('woff2');
        }
        @media print {
          .noprint {
            display: none !important;
          }
        }
        `
      }
    }
  });

export default theme;

import React from 'react';
import Footer from '../components/Footer';
import FormContainer from '../components/FormContainer';

import Header from '../components/Header';
import Intro from '../components/Intro';

const Home = () => (
  <>
    <Header />
    <Intro />
    <FormContainer />
    <Footer />
  </>
);

export default Home;

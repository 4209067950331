import { styled } from "@mui/material";

const StyledFieldset = styled('fieldset')(({ theme }) => ({
  border: `1px solid #ccc`,
  borderRadius: '0.5rem',
  padding: '1rem',
  marginBottom: '1rem',
  '> legend': {
    background: 'white',
    marginLeft: '-0.5rem',
    padding: '0 0.5rem',
    fontFamily: 'neo_sans',
    fontWeight: 'bold',
  },
  [theme.breakpoints.up('md')]: {
    borderRadius: '1rem',
    padding: '2rem',
    marginBottom: '2rem',
  },
}));

export default StyledFieldset;

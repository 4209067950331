import { Box, Grid } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledFieldset from './StyledFieldset';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const MissionApproved = ({ data }) => {
  const { t } = useTranslation();
  const [diver, setDiver] = React.useState({});
  const id = data.antrag_ep_einsatz_genehmigt_von;

  React.useEffect(() => {
    const controller = new AbortController();

    const fetchDiver = async () => {
      await axios
        .get('/thx.php', {
          signal: controller.signal,
          params: {
            'taucher_laden': true,
            id,
          },
        })
        .then((response) => {
          setDiver(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (id) fetchDiver();

    return () => {
      controller.abort();
    }
  }, [id]);

  return id > 0 && (
    <StyledFieldset>
      <legend>{t('mission.approved.label')}</legend>
      <Grid container>
        <Grid item xs={6} md={3}>
          {t('mission.approved.by')}
        </Grid>
        <Grid item xs={6} md={9}>
          {diver.name}
        </Grid>
        <Grid item xs={6} md={3}>
          {t('mission.approved.entry')}
        </Grid>
        <Grid item xs={6} md={9}>
          {moment(data.antrag_datum).format(t('form.dateFormat'))}
        </Grid>
        <Grid item xs={6} md={3}>
          {t('mission.approved.on')}
        </Grid>
        <Grid item xs={6} md={9}>
          {data.antrag_ep_einsatz_genehmigt_am}
        </Grid>
      </Grid>
      {data.antrag_ep_einsatz_genehmigt_am.length > 5 && (
        <Box sx={{ mt: '1rem' }}>
          <img src={`${process.env.REACT_APP_API_BASE_URL}/img/otto_philipp.jpg`} alt="P. Fischer Unterschrift" />
        </Box>
      )}
    </StyledFieldset>
  );
};

MissionApproved.propTypes = {
  data: PropTypes.shape({
    antrag_ep_einsatz_genehmigt_am: PropTypes.string,
    antrag_ep_einsatz_genehmigt_von: PropTypes.string,
    antrag_datum: PropTypes.string,
  }),
};

MissionApproved.defaultProps = {
  data: {},
};

export default MissionApproved;
import { Box, Container, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageButton from './LanguageButton';

import Logo from './Logo';

const TopBar = styled(Box)({
  backgroundColor: '#f5f5f5',
});

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '1rem',
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  '.project-title': {

  }
}));

const StyledLogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'flex-end',
  '.logo-text': {
    marginLeft: '1rem',
    fontFamily: 'neo_sans',
  }
});

const Header = () => {
  const { t } = useTranslation();
  return (
    <header>
      <TopBar className="noprint">
        <Container>
          <LanguageButton />
        </Container>
      </TopBar>
      <StyledContainer>
        <h1 className="project-title">
          {t('projectTitle')}
        </h1>
        <StyledLogoContainer>
          <Logo />
          <Box className="logo-text">
            <strong>Alfred-Wegener-Institut</strong><br />
            Helmholtz-Zentrum für Polar-<br />
            und Meeresforschung
          </Box>
        </StyledLogoContainer>
      </StyledContainer>
    </header>
  );
}

export default Header;

import axios from 'axios';
import CancelIcon from '@mui/icons-material/Cancel';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const StyledGrid = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  '.icon.MuiGrid-item': {
    alignItems: 'center',
    display: 'flex',
    span: {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Diver = ({ id, insurance, label, minDives, name, mission_end }) => {
  const { t } = useTranslation();
  const [diver, setDiver] = React.useState({});

  React.useEffect(() => {
    const controller = new AbortController();

    const fetchDiver = async () => {
      await axios
        .get('/thx.php', {
          signal: controller.signal,
          params: {
            'taucher_laden': true,
            mission_end,
            id,
          },
        })
        .then((response) => {
          setDiver(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (id) fetchDiver();

    return () => {
      controller.abort();
    }
  }, [id, mission_end]);

  return diver && (
    <>
      <Grid item xs={12} md={6} display="flex" flexDirection="column">
        <span>{label}</span>
        <strong>{diver.name || name}</strong>
      </Grid>
      <Grid item xs={3} md={1} className="icon">
        {diver.g31 ? <VerifiedIcon color="success" /> : <CancelIcon color="error" />}
        <span>G31</span>
      </Grid>
      <Grid item xs={3} md={1} className="icon">
        {diver.hlw ? <VerifiedIcon color="success" /> : <CancelIcon color="error" />}
        <span>HLW</span>
      </Grid>
      <Grid item xs={6} sm={3} md={2} className="icon">
        {minDives ? <VerifiedIcon color="success" /> : <CancelIcon color="error" />}
        <span>12TG(300min)</span>
      </Grid>
      <Grid item xs={12} sm={3} md={2} className="icon">
        {insurance ? <VerifiedIcon color="success" /> : <CancelIcon color="error" />}
        <span>{t('mission.insurance')}</span>
      </Grid>
    </>
  );
};

Diver.propTypes = {
  id: PropTypes.string.isRequired,
  insurance: PropTypes.string,
  label: PropTypes.string,
  minDives: PropTypes.string,
  mission_end: PropTypes.string,
  name: PropTypes.string,
};

const MissionDivers = ({ data }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <StyledGrid container spacing={isDesktop ? 4 : 2}>
      {data?.antrag_ep_einsatzleiter_id && (
        <Diver
          label={t('form.diveMissionLeader')}
          id={data?.antrag_ep_einsatzleiter_id}
          insurance={data?.antrag_ep_einsatzleiter_vers}
          minDives={data?.antrag_ep_einsatzleiter_12TG}
          mission_end={data?.antrag_geplant_ende}
          name={data?.antrag_einsatzleiter}
        />
      )}
      {[...Array(10)].map((_, i) => (data[`antrag_einsatztaucher${i + 1}`] || data[`antrag_ep_einsatztaucher${i + 1}_id`] > 0) && (
        <Diver
          key={i}
          label={t('mission.diver', { number: i + 1 })}
          id={data[`antrag_ep_einsatztaucher${i + 1}_id`]}
          insurance={data[`antrag_ep_einsatztaucher${i + 1}_vers`]}
          minDives={data[`antrag_ep_einsatztaucher${i + 1}_12TG`]}
          mission_end={data?.antrag_geplant_ende}
          name={data[`antrag_einsatztaucher${i + 1}`]}
        />
      ))}
    </StyledGrid>
  );
};

MissionDivers.propTypes = {
  data: PropTypes.shape({
    antrag_einsatzleiter: PropTypes.string,
    antrag_ep_einsatzleiter_12TG: PropTypes.string,
    antrag_ep_einsatzleiter_id: PropTypes.string,
    antrag_ep_einsatzleiter_vers: PropTypes.string,
    antrag_geplant_ende: PropTypes.string,
  }),
};

MissionDivers.defaultProps = {
  data: {},
};

export default MissionDivers;
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Mission from '../components/Mission';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const Application = () => {
  const { code } = useParams();
  const [application, setApplication] = React.useState({});

  React.useEffect(() => {
    const controller = new AbortController();

    const fetchApplication = async () => {
      await axios
        .get('/thx.php', {
          signal: controller.signal,
          params: {
            'antrag_laden': true,
            code,
          },
        })
        .then((response) => {
          setApplication(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    fetchApplication();

    return () => {
      controller.abort();
    }
  }, [code]);

  return (
    <>
      <Header />
      <Mission application={application} />
      <Footer />
    </>
  );
};

export default Application;
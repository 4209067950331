import { Alert, Container, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Form from './Form';

const StyledContainer = styled(Container)({
  marginTop: '2rem',
});

const FormContainer = () => {
  const { t } = useTranslation();
  const [error, setError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  return (
    <StyledContainer>
      <h2>{t('form.title')}</h2>
      {error && <Alert severity="error">{error}</Alert>}
      {success ? (
        <Alert severity="success">{t('form.success')}</Alert>
      ) : (
        <Form setError={setError} setSuccess={setSuccess} />
      )}
    </StyledContainer>
  );
};

export default FormContainer;
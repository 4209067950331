import axios from 'axios';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledFieldset from './StyledFieldset';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const MissionFiles = ({ id }) => {
  const [files, setFiles] = React.useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    const controller = new AbortController();

    const fetchDiver = async () => {
      await axios
        .get('/thx.php', {
          signal: controller.signal,
          params: {
            'dateien_laden': true,
            id,
          },
        })
        .then((response) => {
          setFiles(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (id) fetchDiver();

    return () => {
      controller.abort();
    }
  }, [id]);

  return files.length > 0 && (
    <StyledFieldset>
      <legend>{t('mission.files')}</legend>
      <Stack>
        {files.map((file) => (
          <Box key={file.antrag_dateien_hash} alignItems="center" display="flex">
            <DownloadIcon sx={{ mr: '0.5rem' }} />
            <a href={`${process.env.REACT_APP_API_BASE_URL}/download.php?code=${file.antrag_dateien_hash}`} target="_blank" rel='noreferrer'>
              {file.antrag_dateien_name}
            </a>
          </Box>
        ))}
      </Stack>
    </StyledFieldset>
  );
};

MissionFiles.propTypes = {
  id: PropTypes.string,
};

MissionFiles.defaultProps = {
  id: '',
};

export default MissionFiles;
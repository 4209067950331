
const convertLineBreaks = (text) => text?.replace(/(\\r\\n|\\n|\\r)/gm, "<br>") || '';

const stripHTML = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return stripHTMLComments(doc.body.textContent) || '';
}

const stripHTMLComments = (html) => html.replace(/<!--(.*?)-->/gm, "");

export {
  convertLineBreaks,
  stripHTML,
  stripHTMLComments,
}
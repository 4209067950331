import React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';

import Application from './pages/Application';
import Home from './pages/Home';

function App() {
  return (
    <Routes>
      <Route path="antrag/:code" element={<Application />} />
      <Route exact path="/" element={<Home />} />
    </Routes>
  );
}

export default App;

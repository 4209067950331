import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: 'form.error.mixed.default',
    required: 'form.error.mixed.required',
  },
  string: {
    email: 'form.error.email.valid',
    min: 'form.error.email.min',
  },
});

export default yup;

import { Container, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Container)({
  fontSize: '0.875rem',
});

const Intro = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <div dangerouslySetInnerHTML={{ __html: t('projectIntro') }} />
    </StyledContainer>
  );
};

export default Intro;

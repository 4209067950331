import axios from 'axios';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StyledFieldset from './StyledFieldset';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const MissionRiskAssessment = ({ data }) => {
  const { t } = useTranslation();
  const [diver, setDiver] = React.useState({});
  const id = data.antrag_ep_gefahrenanalyse_ersteller;

  React.useEffect(() => {
    const controller = new AbortController();

    const fetchDiver = async () => {
      await axios
        .get('/thx.php', {
          signal: controller.signal,
          params: {
            'taucher_laden': true,
            id,
          },
        })
        .then((response) => {
          setDiver(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    if (id) fetchDiver();

    return () => {
      controller.abort();
    }
  }, [id]);

  return id > 0 && (
    <StyledFieldset>
      <legend>{t('mission.riskAssessment.label')}</legend>
      <Grid container>
        <Grid item xs={6} md={3}>
          {t('mission.riskAssessment.by')}
        </Grid>
        <Grid item xs={6} md={9}>
          {diver.name}
        </Grid>
        <Grid item xs={6} md={3}>
          {t('mission.riskAssessment.submitted')}
        </Grid>
        <Grid item xs={6} md={9}>
          {data.antrag_ep_gefahrenanalyse_eingang}
        </Grid>
        <Grid item xs={6} md={3}>
          {t('mission.riskAssessment.approved')}
        </Grid>
        <Grid item xs={6} md={9}>
          {data.antrag_ep_gefahrenanalyse_bestaetigt}
        </Grid>
      </Grid>
    </StyledFieldset>
  );
};

MissionRiskAssessment.propTypes = {
  data: PropTypes.shape({
    antrag_ep_gefahrenanalyse_ersteller: PropTypes.string,
    antrag_ep_gefahrenanalyse_eingang: PropTypes.string,
    antrag_ep_gefahrenanalyse_bestaetigt: PropTypes.string,
  }),
};

MissionRiskAssessment.defaultProps = {
  data: {},
};

export default MissionRiskAssessment;

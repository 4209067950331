import { Box, Grid, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StyledFieldset from './StyledFieldset';

const StyledBox = styled(Box)(({ theme }) => ({
  '.gridDisplay': {
    display: 'grid',
  },
  '.tableDisplay': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    '.gridDisplay': {
      display: 'none',
    },
    '.tableDisplay': {
      display: 'block',
    },
  },
}));

const MissionProjects = ({ projects }) => {
  const { t } = useTranslation();
  return projects.length > 0 && (
    <StyledBox>
      <StyledFieldset>
        <legend>{t('form.projects')}</legend>
        <Box className="gridDisplay">
          {projects.map((p, index) => (
            <Grid container key={`row${index}`} sx={{ mt: index > 0 ? '1rem' : 0 }}>
              <Grid item xs={12}>{`# ${p.projekt_nummer}.`}</Grid>
              <Grid item xs={6}>
                {t('form.shortName')}
              </Grid>
              <Grid item xs={6}>
                {p.projekt_kurzname}
              </Grid>
              <Grid item xs={6}>
                {t('form.applicant')}
              </Grid>
              <Grid item xs={6}>
                {p.projekt_antragsteller}
              </Grid>
              <Grid item xs={6}>
                {t('form.mailAddress')}
              </Grid>
              <Grid item xs={6}>
                {p.projekt_mailadresse}
              </Grid>
              <Grid item xs={6}>
                {t('form.costCentre')}
              </Grid>
              <Grid item xs={6}>
                {p.projekt_kostenstelle}
              </Grid>
              <Grid item xs={6}>
                {t('form.invoiceAddress')}
              </Grid>
              <Grid item xs={6}>
                {p.projekt_rechnungsadresse}
              </Grid>
              <Grid item xs={6}>
                {t('form.comment')}
              </Grid>
              <Grid item xs={6}>
                {p.projekt_kommentar}
              </Grid>
            </Grid>
          ))}
        </Box>
        <TableContainer className="tableDisplay" component={Box}>
          <Table size="small" aria-label={t('form.projectTable')}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>{t('form.shortName')}</TableCell>
                <TableCell>{t('form.applicant')}</TableCell>
                <TableCell>{t('form.mailAddress')}</TableCell>
                <TableCell>{t('form.costCentre')}</TableCell>
                <TableCell>{t('form.invoiceAddress')}</TableCell>
                <TableCell>{t('form.comment')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((p, index) => (
                <TableRow key={`row${index}`}>
                  <TableCell>{p.projekt_nummer}.</TableCell>
                  <TableCell>{p.projekt_kurzname}</TableCell>
                  <TableCell>{p.projekt_antragsteller}</TableCell>
                  <TableCell>{p.projekt_mailadresse}</TableCell>
                  <TableCell>{p.projekt_kostenstelle}</TableCell>
                  <TableCell>{p.projekt_rechnungsadresse}</TableCell>
                  <TableCell>{p.projekt_kommentar}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledFieldset>
    </StyledBox>
  );
};

MissionProjects.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      shortName: PropTypes.string,
      applicant: PropTypes.string,
      mailAddress: PropTypes.string,
      costCentre: PropTypes.string,
      invoiceAddress: PropTypes.string,
      comment: PropTypes.string,
    }),
  ),
};

MissionProjects.defaultProps = {
  projects: [],
};

export default MissionProjects;

import React from 'react';
import { styled } from '@mui/material/styles';

const StyledSVG = styled('svg')(({ theme }) => ({
  width: 200,
  maxWidth: '100%',
  fill: 'white',
  '.st0': {
    fill: '#00ABE8',
  },
  [theme.breakpoints.between(900, 980)]: {
    // width: 580,
  }
}));

const Logo = () => (
  <StyledSVG
    id="awi-logo"
    data-name="Alfred Wegener Institut Logo"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="317.6 -26.8 702 294.5"
  >
    <path className="st0" d="M1005.9,32.9c-10.1-3.2-20.7,2.9-23.7,13.7l-54.4,195c-2.9,10.8,2.8,22,12.9,25.2c10.1,3.2,20.7-2.9,23.7-13.6
	l54.4-195C1021.8,47.4,1016,36,1005.9,32.9z M945.5,58c2.9-10.7-2.8-22-12.9-25.2c-10.1-3.2-20.6,2.9-23.7,13.6l-45.6,163.3
	c-0.7,2.7-2.9,4.6-5.6,4.6s-5-1.9-5.6-4.6L828.7,126l0,0l-17.9-63.8C806,44.7,790.9,32,773.1,32c-17.8,0-32.8,12.6-37.7,29.9
	L700,188.7l0,0l-5.9,21c-0.7,2.7-2.9,4.6-5.6,4.6s-5-1.9-5.6-4.6l-5.8-21l-2.5-9.1l-12.2-43.8L641.8,62
	c-4.7-17.4-19.8-30.1-37.7-30.1c-17.8,0-32.8,12.6-37.7,29.9l-19.3,69l0,0l-30.9,110.6c-2.9,10.8,2.8,22,12.9,25.2
	c10.1,3.2,20.6-2.9,23.7-13.6l6.4-22.7H599c10.5,0,19-9.1,19-20.3s-8.5-20.3-19-20.3h-28.5l0.3-1l27.6-98.9c0.7-2.6,2.9-4.6,5.6-4.6
	s5,2,5.6,4.6l27.6,98.9l13.7,49c4.8,17.3,19.8,29.9,37.6,29.9c17.9,0,32.9-12.7,37.7-30.1l31.4-112.3l9.8-35.3
	c0.7-2.6,2.9-4.6,5.6-4.6s5,2,5.6,4.6l27.6,98.9l0,0l13.7,49c4.8,17.3,19.8,29.9,37.6,29.9s32.9-12.7,37.7-30.1L945.5,58L945.5,58z
	 M520.3,2.6l-12.8-6.5l4.7-14.3l-13.4,5.1l-6.1-13.7l-6.1,13.7l-13.4-5.1l4.7,14.3l-12.8,6.5l12.8,6.5l-4.7,14.3l13.4-5.1l6.1,13.7
	l6.1-13.7l13.4,5.1l-4.7-14.3L520.3,2.6z M460.2,47.2l-1.8-1.2c-1.8-1.1-3.4-2.1-4.9-2.9c-16.6-9.1-35.8-13-55.7-10.1
	c-51,7.6-86.4,57.8-79.3,112c7.2,54.3,54.3,92,105.2,84.4c21-3.2,39.3-13.5,53.1-28.4c1.1-1.2,3.1-3.2,3.9-4.2
	c1.1-1.3-0.4-1.8-1.4-0.8c-0.8,0.7-3.2,2.8-4.5,4c-0.7,0.7-1.9,2-3.4,1.4c-0.1,0-2.6-1.4-3.8-1.9c-1.4-0.7-2.7-1.1-4.2-1.6
	c-6.3-2.1-13.8-3.2-21.8-3.2c-1.6,0-3.1-0.9-2.8-2.9c1.4-10.6,2-21.6,1.7-32.8c0-2.2-0.4-3.6,1.8-4.4c1.8-0.7,3.6-1.4,5.4-2.1
	c2.8-1.1,6.4-2.4,6.5-3.4c0-0.7-1.4-0.1-6.8,1.5c-1.8,0.5-3.5,1.1-5.3,1.5c-1.3,0.4-1.9-0.3-2-1.9c-0.5-7.3-1.3-14.8-2.5-22.2
	c-4-24.6-12-47.1-22.5-66.4c-0.5-0.8-0.7-1.7,0.6-2.2c3.4-1.3,6.5-2.8,9.4-4.3c1.7-0.9,2-0.5,3.2,0.2c8,4.5,15.3,10.4,21.9,17.5
	c20.9,22.7,29.5,53.8,26.1,85.1c-0.4,4.3-1.1,8-0.4,8.2s1.7-5.2,2.2-7.9c6.6-34.7-1.4-70.3-24.6-95.5c-4.6-5.1-9.7-9.4-15-13.1
	c-1.4-1-3.1-1.4-1-3.6l0.8-0.9c0.3-0.3,0.7-0.7,1.2-1.4l0.5-0.6c0.4-0.4,1-1,1.7-1.1c1.1-0.1,4,0.4,10.2,3.2
	c6.2,2.8,7.6,3.7,8.4,3.7C460.8,49,461.1,47.8,460.2,47.2z M397.3,202.5c-11-8.6-20.2-19.6-26.8-33l-0.2-0.4c-0.9-2,0-3,1.9-2.9
	c19.7,0.4,38.8-2.2,56.7-7.2c1.9-0.6,2.8-0.5,2.9,1.8c0.7,10.5,0.7,20.8-0.1,30.8c-0.2,2.8-0.4,3.7-3,4c-1.4,0.2-2.9,0.4-4.4,0.7
	c-7.7,1.5-14.9,3.8-21.3,6.6C400.7,204.1,399.5,204.2,397.3,202.5z M343.5,163.5c2.9,0.5,5.9,1,8.9,1.3l3.3,0.4
	c2.7,0.3,2.6,0.5,4,3.5l0.4,0.8c7.6,15.6,18.7,28.1,32.2,37.2c0.9,0.6,0.8,1.8-0.1,2.5c-1.5,1-2.9,2.1-4.3,3.2c-1.2,1-2,0.9-3.5,0.2
	c-18.4-9.2-33.4-25.2-41.9-45.4C341.5,164.5,341.9,163.2,343.5,163.5z M364.3,70c-13.7,25.8-18,55.2-10.6,82.3
	c0.7,2.8,0.4,4-2.4,3.6c-3.7-0.7-7.4-1.5-11.1-2.5c-2.3-0.6-2.4-0.4-3.2-4.4c-0.4-2.2-0.8-4.3-1.1-6.5c-3.9-29.2,5.7-57,23.6-76.6
	c0.5-0.5,1.3-1.3,3.1-1l3,0.6C366.8,65.6,365.1,68.5,364.3,70z M429.4,139.3c0.7,4.2,1.2,8.4,1.7,12.6c0.2,2.3,0,3-2.3,3.5
	c-17.1,3.5-35.1,4.7-53.7,3.5c-2.3-0.2-4.5-0.4-6.8-0.6c-2-0.2-2.7-0.9-3.6-4c-8.3-28-4-58.7,10.5-85.5c0.4-0.7,1-2.4,4.1-2.4
	c5.8,0,12-0.6,18.4-1.8l0.7-0.1c2.7-0.5,3.7,0,4.9,2.1C415.6,87.3,424.8,112,429.4,139.3z"/>
  </StyledSVG>
);

export default Logo;

import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, InputLabel, styled } from '@mui/material';

// Import translations for the German language.
import '@ckeditor/ckeditor5-build-balloon/build/translations/de';

const getColor = (theme, error, focused) => {
  if (error) return theme.palette.error.main;
  if (focused) return theme.palette.primary.main;
  return theme.palette.text.main;
};

const getBorderColor = (theme, error, focused) => {
  if (error) return theme.palette.error.main;
  if (focused) return theme.palette.primary.main;
  return 'rgba(0, 0, 0, 0.23)';
};

const StyledLabel = styled(InputLabel)(({ theme, error, focused }) => ({
  color: getColor(theme, error, focused),
  background: 'white',
  padding: '0 0.5rem',
  marginLeft: '-0.25rem',
}));

const StyledTextarea = styled('div')(({ theme, error, focused }) => ({
  outline: `1px solid ${getBorderColor(theme, error, focused)}`,
  outlineWidth: `${focused ? '2px' : '1px'}`,
  borderRadius: '4px',
  padding: '15.5px 14px',
  '.MuiInputLabel-shrink': {
    background: 'white !important',
    padding: '0 0.5rem',
  },
  '.ck.ck-editor__editable_inline': {
    border: 'none',
    minHeight: '9rem',
    padding: 0,
    '> :first-child': { marginTop: 0 },
    '> :last-child': { marginBottom: 0 },
  },
  '.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable)': {
    border: 'none',
    boxShadow: 'none',
    outline: 0,
  },
}));

const StyledFormHelperText = styled(FormHelperText)(({ theme, error }) => ({
  color: getColor(theme, error, false),
}));

const RichTextEditor = ({ error, helperText, label, name, onBlur, onChange, required, value }) => {
  const [focused, setFocused] = React.useState(false);

  const editorConfig = () => ({
    toolbar: {
      items: [
        'bold',
        'italic',
        '|',
        'numberedList',
        'bulletedList',
      ],
      shouldNotGroupWhenFull: true,
    },
    language: 'de',
  });

  const handleChange = React.useCallback((e, editor) => {
    const valueEmpty = !value;
    const data = editor.getData();
    const dataEmpty = !data;
    if (valueEmpty !== dataEmpty) {
      onChange({ target: { name, value: data } });
    }
  }, [name, onChange, value]);

  const handleBlur = React.useCallback((e, editor) => {
    setFocused(false);
    onBlur({ target: { name } });
    onChange({ target: { name, value: editor.getData() } });
  }, [name, onBlur, onChange]);

  return (
    <FormControl
      fullWidth
      required={required}
      sx={{ my: '1rem' }}
    >
      <StyledLabel htmlFor={`${name}-editor`} shrink focused={focused} error={!!error}>{label}</StyledLabel>
      <StyledTextarea focused={focused} error={!!error}>
        <CKEditor
          id={`${name}-editor`}
          config={editorConfig}
          editor={BalloonEditor}
          data={value}
          onError={(error) => { console.log(error); }}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={() => setFocused(true)}
        />
      </StyledTextarea>
      <StyledFormHelperText error={!!error}>{helperText}</StyledFormHelperText>
    </FormControl>
  );
};

RichTextEditor.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.string,
};

RichTextEditor.defaultProps = {
  error: false,
  helperText: '',
  label: '',
  required: false,
  value: '',
};

export default RichTextEditor;
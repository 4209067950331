import { Container, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledContainer = styled(Container)({
  
});

const Footer = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <div dangerouslySetInnerHTML={{ __html: t('imprint') }} />
    </StyledContainer>
  );
};

export default Footer;

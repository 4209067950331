import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip } from '@mui/material';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormProjects = ({
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
}) => {
  const { t } = useTranslation();
  return (
    <FieldArray
      name="projects"
      render={(fieldArrayProps) => (
        <TableContainer component={Box}>
          {values.projects.length > 0 && (
            <Table size="small" aria-label={t('form.projectTable')}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{t('form.shortName')} *</TableCell>
                  <TableCell>{t('form.applicant')} *</TableCell>
                  <TableCell>{t('form.mailAddress')} *</TableCell>
                  <TableCell>{t('form.costCentre')} *</TableCell>
                  <TableCell>{t('form.invoiceAddress')} *</TableCell>
                  <TableCell>{t('form.comment')}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {values.projects.map((project, index) => (
                  <TableRow key={`row${index}`} sx={{
                    '.MuiTableCell-root': { padding: '0.5rem' },
                  }}>
                    <TableCell>{index + 1}.</TableCell>
                    <TableCell>
                      <TextField
                        error={!!(touched.projects?.length
                          && touched.projects[index]?.shortName
                          && errors.projects?.length
                          && errors.projects[index]?.shortName)}
                        fullWidth
                        name={`projects.${index}.shortName`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={project.shortName}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        error={!!(touched.projects?.length
                          && touched.projects[index]?.applicant
                          && errors.projects?.length
                          && errors.projects[index]?.applicant)}
                        fullWidth
                        name={`projects.${index}.applicant`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={project.applicant}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        error={!!(touched.projects?.length
                          && touched.projects[index]?.mailAddress
                          && errors.projects?.length
                          && errors.projects[index]?.mailAddress)}
                        fullWidth
                        name={`projects.${index}.mailAddress`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={project.mailAddress}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        error={!!(touched.projects?.length
                          && touched.projects[index]?.costCentre
                          && errors.projects?.length
                          && errors.projects[index]?.costCentre)}
                        fullWidth
                        name={`projects.${index}.costCentre`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={project.costCentre}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        error={!!(touched.projects?.length
                          && touched.projects[index]?.invoiceAddress
                          && errors.projects?.length
                          && errors.projects[index]?.invoiceAddress)}
                        fullWidth
                        name={`projects.${index}.invoiceAddress`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        value={project.invoiceAddress}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        name={`projects.${index}.comment`}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={project.comment}
                      />
                    </TableCell>
                    <TableCell>
                      <Tooltip title={t('form.removeProject')}>
                        <span>
                          <IconButton disabled={(index === 0)} onClick={() => fieldArrayProps.remove(index)} size="small">
                            <ClearIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
          <Button
            onClick={() => fieldArrayProps.push({
              shortName: '',
              applicant: '',
              mailAddress: '',
              costCentre: '',
              invoiceAddress: '',
              comment: '',
            })}
            startIcon={<AddIcon />}
            sx={{ marginTop: '1rem' }}
            variant="outlined"
          >
            {t('form.addProject')}
          </Button>
        </TableContainer>
      )}/>
  );
}

FormProjects.propTypes = {
  errors: PropTypes.shape({
    projects: PropTypes.arrayOf(PropTypes.shape({
      shortName: PropTypes.string,
      applicant: PropTypes.string,
      mailAddress: PropTypes.string,
      costCentre: PropTypes.string,
      invoiceAddress: PropTypes.string,
    })),
  }),
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    projects: PropTypes.arrayOf(PropTypes.shape({
      shortName: PropTypes.bool,
      applicant: PropTypes.bool,
      mailAddress: PropTypes.bool,
      costCentre: PropTypes.bool,
      invoiceAddress: PropTypes.bool,
    })),
  }),
  values: PropTypes.shape({
    projects: PropTypes.arrayOf(PropTypes.shape({
      shortName: PropTypes.string,
      applicant: PropTypes.string,
      mailAddress: PropTypes.string,
      costCentre: PropTypes.string,
      invoiceAddress: PropTypes.string,
      comment: PropTypes.string,
    })), 
  }),
};

FormProjects.defaultProps = {
  errors: {},
  open: false,
  handleBlur: null,
  handleChange: null,
  setOpen: null,
  touched: {},
  values: {},
};

export default FormProjects;
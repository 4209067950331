import { Button, Container, Grid, styled } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MissionApproved from './MissionApproved';
import MissionProjects from './MissionProjects';
import MissionDivers from './MissionDivers';
import MissionFiles from './MissionFiles';
import MissionRiskAssessment from './MissionRiskAssessment';
import StyledFieldset from './StyledFieldset';
import { convertLineBreaks } from '../methods/general';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const StyledContainer = styled(Container)({
  marginTop: '2rem',
  '.mission-link': {
    wordBreak: 'break-word',
  },
});

const Mission = ({ application }) => {
  const { daten } = application;
  const { t } = useTranslation();
  const link = `${BASE_URL}/antrag/${daten?.antrag_hash}`;

  return (
    <StyledContainer>
      <StyledFieldset>
        <legend>{t('mission.title')}</legend>
        <Grid container>
          <Grid item xs={6} md={3}>
            {t('mission.lastUpdate')}
          </Grid>
          <Grid item xs={6} md={9}>
            {daten?.antrag_timestamp}
          </Grid>
          <Grid item xs={12} md={3}>
            {t('mission.link')}
          </Grid>
          <Grid item xs={12} md={9}>
            <a href={`${link}`} className="mission-link">{`${link}`}</a>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: '1rem' }}>
          <Grid item xs={6} md={3}>
            {t('form.plannedMissionStart')}
          </Grid>
          <Grid item xs={6} md={9}>
            {daten?.antrag_geplant_beginn}
          </Grid>
          <Grid item xs={6} md={3}>
            {t('form.plannedMissionEnd')}
          </Grid>
          <Grid item xs={6} md={9}>
            {daten?.antrag_geplant_ende}
          </Grid>
        </Grid>
        <Grid container sx={{ mt: '1rem' }}>
          <Grid item xs={6} md={3}>
            {t('form.shortDescription')}
          </Grid>
          <Grid item xs={6} md={9}>
            {daten?.antrag_kurzbezeichnung}
          </Grid>
        </Grid>
      </StyledFieldset>
      <StyledFieldset>
        <legend>{t('mission.applicant')}</legend>
        <Grid container>
          <Grid item xs={6} md={3}>
            {t('form.fullname')}
          </Grid>
          <Grid item xs={6} md={9}>
            {daten?.antrag_antragsteller}
          </Grid>
          <Grid item xs={6} md={3}>
            {t('form.email')}
          </Grid>
          <Grid item xs={6} md={9}>
            {daten?.antrag_antragsteller_email}
          </Grid>
        </Grid>
      </StyledFieldset>
      <MissionProjects projects={daten?.antrag_projekte} />
      <StyledFieldset>
        <legend>{t('form.missionDescription')}</legend>
        <div dangerouslySetInnerHTML={{ __html: convertLineBreaks(daten?.antrag_projektbeschreibung) }} />
      </StyledFieldset>
      <StyledFieldset>
        <legend>{t('form.diveSiteLocation')}</legend>
        <div dangerouslySetInnerHTML={{ __html: convertLineBreaks(daten?.antrag_tauchgebiet) }} />
      </StyledFieldset>
      <StyledFieldset>
        <p>
          {t('form.numberOfPlanedDives')} <strong>{daten?.antrag_anzahl_tauchgaenge}</strong>
        </p>
        <p>
          {t('form.maximumDiveDepth')} <strong>{daten?.antrag_max_einsatztiefe}</strong>
        </p>
        <p>
          {t('form.maximumDiveTime')} <strong>{daten?.antrag_max_einsatzdauer}</strong>
        </p>
        <p>
          {t('form.plannedDecompressionDives')} <strong>{daten?.antrag_dekompressionstauchgaenge}</strong>
        </p>
        <p>
          {t('form.breathingGas.label')} <strong>{daten?.antrag_atemgas}</strong>
        </p>
      </StyledFieldset>
      <StyledFieldset>
        <legend>{t('mission.plannedUnderwaterWork')}</legend>
        <div dangerouslySetInnerHTML={{ __html: convertLineBreaks(daten?.antrag_aufgaben) }} />
      </StyledFieldset>
      <StyledFieldset>
        <legend>{t('mission.divers')}</legend>
        <MissionDivers data={daten} />
      </StyledFieldset>
      <MissionFiles id={daten?.antrag_id} />
      <StyledFieldset>
        <legend>{t('mission.furtherComments')}</legend>
        {daten?.antrag_bemerkungen ? (
          <div dangerouslySetInnerHTML={{ __html: convertLineBreaks(daten?.antrag_bemerkungen) }} />
        ) : t('general.none')}
      </StyledFieldset>
      <MissionRiskAssessment data={daten} />
      <StyledFieldset>
        <legend>{t('mission.specialDefinitions')}</legend>
        {daten?.antrag_besonderegenehmigungen ? (
          <div dangerouslySetInnerHTML={{ __html: convertLineBreaks(daten?.antrag_besonderegenehmigungen) }} />
        ) : t('general.none')}
      </StyledFieldset>
      <MissionApproved data={daten} />
      <Button className="noprint" onClick={() => window.print()} variant="contained" color="primary" sx={{ my: '1rem' }}>
        {t('general.print')}
      </Button>
    </StyledContainer>
  );
};

Mission.propTypes = {
  application: PropTypes.object,
};

Mission.defaultProps = {
  application: {},
};

export default Mission;
import PublicIcon from '@mui/icons-material/Public';
import { Box, Button } from '@mui/material';
import { t } from 'i18next';
import React from 'react';

import i18n from '../i18n';

const LanguageButton = () => {

  const toggleLanguage = () => {
    const language = i18n.getLang().toLowerCase() === 'de' ? 'en' : 'de';
    i18n.changeLanguage(language);
  }

  return (
    <Box>
      <Button
        onClick={toggleLanguage}
        variant="text"
      >
        <PublicIcon sx={{ marginRight: '0.25rem' }} />
        {t(`languageButtonSwitcher.${i18n.getLang()}`)}
      </Button>
    </Box>
  );
};

export default LanguageButton;
